<template>
    <form class="container" @submit.prevent="sendForm()">
        <h3>{{$t('ask-visit.ask-visit-form')}}</h3>
        <p>
            <strong>{{$t('ask-visit.asking-by')}}: {{this.$route.params.idalojamiento}}</strong>
        </p>
        <p>
            {{$t('ask-visit.call-us')}}
            <a href="tel:+56976934003">+569 7693 4003</a>
        </p>
        <input type="hidden" v-model="form_data.id_alojamiento" />

        <label>{{$t('ask-visit.name')}}</label>
        <input
            type="text"
            id="nombre"
            class="form-control form-control-lg"
            placeholder="Pablo González"
            required
            v-model="form_data.nombre"
        />

        <label>{{$t('ask-visit.address')}}</label>
        <input
            type="text"
            id="direccion"
            class="form-control form-control-lg"
            placeholder="Calle los duraznos 398"
            required
            v-model="form_data.direccion"
        />

        <label>{{$t('ask-visit.age')}}</label>
        <input
            type="text"
            id="edad"
            class="form-control form-control-lg"
            required
            v-model="form_data.edad"
        />

        <label>{{$t('ask-visit.country')}}</label>
        <input
            id="pais"
            type="text"
            class="form-control form-control-lg"
            required
            v-model="form_data.nacionalidad"
        />

        <label>{{$t('ask-visit.phone')}}</label>
        <div class="row">
            <div class="col-4">
                <select
                    v-model="form_data.codigo_pais"
                    name="codigo_pais"
                    class="form-control custom-select"
                    required
                >
                    <optgroup :label="$t('countries-list.highlighted')">
                        <option
                            v-for="(item, i) in highlightedCountriesTranslated"
                            :key="`c-${i}`"
                            :data-country-code="item.code"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </optgroup>
                    <optgroup :label="$t('countries-list.other-countries')">
                        <option
                            v-for="(item, i) in countriesWithCodeTranslated"
                            :key="`c-${i}`"
                            :data-country-code="item.code"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </optgroup>
                </select>
            </div>
            <div class="col-8">
                <input
                    id="telefono"
                    type="text"
                    class="form-control form-control-lg"
                    required
                    v-model="form_data.telefono"
                />
            </div>
        </div>

        <label>{{$t('ask-visit.visit-day')}}</label>
        <input
            id="visita"
            type="text"
            class="form-control form-control-lg"
            required
            v-model="form_data.visita"
        />

        <label>{{$t('ask-visit.occupation')}}</label>
        <select
            id="oficio"
            class="form-control form-control-lg"
            required
            v-model="form_data.oficio"
        >
            <option></option>
            <option value="estudiante">{{$t('ask-visit.studen')}}</option>
            <option value="ejecutivo">{{$t('ask-visit.executive')}}</option>
        </select>

        <label>{{$t('ask-visit.email')}}</label>
        <input
            id="correo"
            type="text"
            class="form-control form-control-lg"
            required
            v-model="form_data.correo"
        />

        <h4>{{$t('ask-visit.prefered-dates')}}</h4>
        
        <DatePicker />

        <label>{{$t('ask-visit.select-picture')}}</label>
        <input
            id="carnet"
            type="file"
            class="form-control form-control-lg"
            data-parsley-type="text"
            ref="carnet"
            v-on:change="handleFileUpload()"
            required
        />

        <small
            class="text-danger"
            v-if="this.form_data.code_api !== false"
        >{{this.form_data.code_api}}</small>
        <small class="text-success" v-if="this.form_data.update === true">{{$t('ask-visit.sent')}}</small>
        <small
            class="text-success"
            v-if="this.form_data.sending === true"
        >{{$t('ask-visit.sending')}}...</small>
        <button
            type="submit"
            class="btn btn-primary px-5"
            v-if="
					this.form_data.sending==false
					&& this.form_data.update==false
				"
        >{{$t('ask-visit.send')}}</button>
    </form>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import router from "@/router";
import countryMixin from "@/mixins/countryMixin";

export default {
    name: 'RequestAccommodationVisit',
    mixins: [ countryMixin ],
    data() {
        return {
            
            form_data: {
                id_alojamiento: this.$route.params.accommodationId,
                nombre: "",
                direccion: "",
                edad: "",
                nacionalidad: "",
                codigo_pais: "",
                telefono: "",
                visita: "",
                oficio: "",
                correo: "",
                fecha_inicio: "",
                fecha_termino: "",
                carnet: "",
                update: false,
                code_api: false,
                sending: false,
                solicitud: "NEW_FRONT_VUE"
            },
            
        };
    },
    components: {
        DatePicker
    },
    computed: {
        ...mapState('search',[
            'checkInDate',
            'checkOutDate'
        ]),
    },
    created() {
        window.scrollTo(0, 0);
    },
    methods: {
        
        handleFileUpload() {
            this.form_data.carnet = this.$refs.carnet.files[0];
        },
        sendForm() {
            // set dates
            this.form_data.fecha_inicio = this.checkInDate;
            this.form_data.fecha_termino = this.checkOutDate;
            this.form_data.sending = true;
            this.form_data.code_api = false;
            let id_alojamiento = this.form_data.id_alojamiento;
            var dataAll = this.form_data;
            var formData = new FormData();
            Object.keys(this.form_data).map(function(objectKey, index) {
                formData.append(objectKey, dataAll[objectKey]);
            });
            formData.append('language', this.$i18n.locale);
            const url = process.env.VUE_APP_API_URL + `reservations/solicitar/${id_alojamiento}`;
            axios.post(url, formData)
                .then((response) => {
                    this.form_data.sending = false;
                    router.push({ 
                        name: "request-visit-success",
                        params: { accommodationId: this.$route.params.accommodationId }
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
    }
};
</script>


<style lang="scss" scoped>
.container {
    h4 {
        margin-top: 40px;
    }

    label {
        margin: 0;
        margin-top: 10px;
    }

    button,
    button:hover {
        margin-top: 40px;
        background-color: $hf-sec-pine;
        border: none;
        margin-bottom: 100px;
    }

    .form-control-lg {
        height: 48px;
    }
}
</style>

<style>

.datepicker {
    z-index: 1021 !important;
}

// Next month button
.next--mobile {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background: $hf-sec-pine;
    height: $see-more-button-height;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1021 !important;
}

</style>
